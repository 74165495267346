@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Eczar:wght@600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
  height: 100%;
  margin: 0;
}
.app-container {
  min-height: 100vh;
  background-color: #EFCDBC;
  display: flex;
}

.main-layout {
  display: flex;
  height: 100vh;
}

/* Mobile Header */
.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #501F21;
  color: #EFCDBC;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #501F21;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  border-right: 1px solid #ddd;
  height: 100%;
  overflow: auto;
}

.nav-button {
  padding: 12px;
  border: none;
  background-color: transparent;
  color: #EFCDBC;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-align: left;
  font-size: 1rem;
}

.nav-button:hover, .nav-button.active {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Content Area */
.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #EFCDBC;
}

.desktop-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}

.desktop-header h1 {
  font-size: 3.5rem;
  color: #501F21;
  margin: 0 0 10px 0;
  text-align: center;
  width: 100%; 
}

.main-content {
  padding: 20px 40px;
}

.main-content p {
  text-align: justify;
  line-height: 1.8;
  margin: 0 auto;
  font-size: 1.1rem;
  color: black;
}

/* Language Switcher */
.language-switcher {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #501F21;
  border-radius: 4px;
  background: #EFCDBC;
  cursor: pointer;
  transition: all 0.3s;
}

.language-switcher:hover {
  background: #f5f5f5;
}

/* Forms */
.order-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.pincode-input {
  width: 90%;
  padding: 12px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #582c2c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #6d3636;
}

.site-title {
  width: 100%;
  text-align: center;
}

.language-switcher-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .mobile-header h1 {
    font-size: 1.8rem;
  }

  .main-content {
    padding: 40px 15px 15px 15px;
  }
  
  .main-content p {
    text-align: left; /* Left align on mobile for better readability */
    font-size: 1rem;
  }
  .main-layout {
    padding-top: 60px;
  }

  .mobile-header {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  .desktop-header {
    display: none;
    padding: 10px;
  }

  .sidebar {
    position: fixed;
    top: 60px;
    left: -300px;
    bottom: 0;
    transition: left 0.3s ease;
    z-index: 900;
  }

  .sidebar.open {
    padding-top: 60px;
    left: 0;
  }

  .content {
    width: 100%;
    padding: 15px;
  }

  .language-switcher-container {
    position: static;
    margin-top: 10px;
  }
}